<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :is-new-button="checkPermission('certificatecourse_store')" :is-filter="false"
                    :title="certificate_name" @new-button-click="addCourseFormShow">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden" :id="'checkbox-' + key" :name="'checkbox-' + key"
                                :value="false" :unchecked-value="true" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :is-new-button="checkPermission('certificatecourse_store')" :is-filter="false"
                    :title="certificate_name" @new-button-click="addCourseFormShow">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden" :id="'checkbox-' + key" :name="'checkbox-' + key"
                                :value="false" :unchecked-value="true" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="addCourseFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('new') }}
                </template>
                <template v-slot:CommonModalContent>
                    <AddCourse :certificate_id="certificate_id" @createFormSuccess="addCourseFormSuccess"
                        v-if="formProcess == 'add_course'" />
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
//Template
import PageHeader from "@/components/page/PageHeader";
//Component
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";
//Other
import qs from "qs";
//Service
import CertificateCourseService from "@/services/CertificateCourseService";

//Page
import AddCourse from "@/modules/certificate/pages/AddCourse/AddCourse";

export default {
    name: "CertificateCoursesPage",
    props: {
        certificate_id: {},
        certificate_name: {}
    },
    components: {
        PageHeader,
        AddCourse,
        Datatable,
        CommonModal,
    },
    metaInfo() {
        return {
            title: this.$t("add_course"),
        };
    },
    data() {
        return {
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "certificatecourse_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id"),
                        field: 'id',
                        sortable: true,
                    },
                    {
                        label: this.$t("name"),
                        field: "course.name",
                        sortable: true,
                    },
                ],
                rows: [],
                realdata: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            this.datatable.queryParams.filter.parameter_id = this.parameter_id;
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };

            return CertificateCourseService.getAll(config)
                .then((response) => {
                    const data = response.data.data
                    this.datatable.rows = []
                    this.datatable.rows = data.filter((item) => {
                        if (item.certificate_id == this.certificate_id) {
                            return item
                        }
                    })

                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        delete(id) {
            this.deleteModal(() => {
                CertificateCourseService.deleteCertificaCourses(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(this.$t("api." + response.data.message));
                    }).finally(
                        this.getRows()
                    )
                    .catch((error) => {
                        this.$toast.error(this.$t("api." + error.data.message));
                    });
            });
        },
        async addCourseFormSuccess() {
            this.$refs.addCourseFormModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        },
        addCourseFormShow() {
            this.formId = null
            this.form = {};
            this.formProcess = 'add_course'
            this.$refs.addCourseFormModal.$refs.commonModal.show()
        },
        formClear() {
            this.formId = null
            this.formProcess = null
        },
    },
    created() {
        this.filter()
    }
};
</script>

